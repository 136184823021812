export const FeatureToggles = {
  FEATURE_CAS_IMAGE_API: 'FEATURE_CAS_IMAGE_API',
  FEATURE_CAS_IMAGE_API_BRANDS: 'FEATURE_CAS_IMAGE_API_BRANDS',
  FEATURE_NEW_RELIC: 'FEATURE_NEW_RELIC',
  FEATURE_IFRAME_RESIZER: 'FEATURE_IFRAME_RESIZER',
  FEATURE_IFRAME_RESIZER_BRANDS: 'FEATURE_IFRAME_RESIZER_BRANDS',
  FEATURE_PAGE_HEADER: 'FEATURE_PAGE_HEADER',
  FEATURE_PAGE_HEADER_LOGO: 'FEATURE_PAGE_HEADER_LOGO',
  FEATURE_PAGE_HEADER_BRANDS: 'FEATURE_PAGE_HEADER_BRANDS',
  FEATURE_PAGE_FOOTER: 'FEATURE_PAGE_FOOTER',
  FEATURE_PAGE_FOOTER_BRANDS: 'FEATURE_PAGE_FOOTER_BRANDS',
  FEATURE_LANDING_PAGE: 'FEATURE_LANDING_PAGE',
  FEATURE_LANDING_PAGE_BRANDS: 'FEATURE_LANDING_PAGE_BRANDS',
  FEATURE_EV_DECISION_TREE: 'FEATURE_EV_DECISION_TREE',
  FEATURE_EV_DECISION_TREE_BRANDS: 'FEATURE_EV_DECISION_TREE_BRANDS',
  FEATURE_EV_DECISION_TREE_SHOW_LEARN_MORE_LINK: 'FEATURE_EV_DECISION_TREE_SHOW_LEARN_MORE_LINK',
  FEATURE_COMPARATOR: 'FEATURE_COMPARATOR',
  FEATURE_COMPARATOR_BRANDS: 'FEATURE_COMPARATOR_BRANDS',
  FEATURE_DETAILED_COMPARATOR: 'FEATURE_DETAILED_COMPARATOR',
  FEATURE_USED_COMPARATOR: 'FEATURE_USED_COMPARATOR',
  FEATURE_DETAILED_COMPARATOR_BRANDS: 'FEATURE_DETAILED_COMPARATOR_BRANDS',
  FEATURE_DETAILED_COMPARATOR_SHOW_TEST_STANDARD: 'FEATURE_DETAILED_COMPARATOR_SHOW_TEST_STANDARD',
  FEATURE_ALLOW_VEHICLES_PRE_2016_TO_BE_DISPLAYED: 'FEATURE_ALLOW_VEHICLES_PRE_2016_TO_BE_DISPLAYED',
  FEATURE_ALLOW_VEHICLES_PRE_2016_TO_BE_DISPLAYED_BRANDS: 'FEATURE_ALLOW_VEHICLES_PRE_2016_TO_BE_DISPLAYED_BRANDS',
  FEATURE_HEALTH_API_ENABLED: 'FEATURE_HEALTH_API_ENABLED',
  FEATURE_ANALYTICS_PERSIST: 'FEATURE_ANALYTICS_PERSIST',
  FEATURE_SHOW_NEDC_FLAG: 'FEATURE_SHOW_NEDC_FLAG',
  FEATURE_SHOW_TEST_TYPE_MODAL: 'FEATURE_SHOW_TEST_TYPE_MODAL',
  FEATURE_SHOW_TEST_TYPE_MODAL_BRANDS: 'FEATURE_SHOW_TEST_TYPE_MODAL_BRANDS',
  FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE: 'FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE',
  FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE_VOLVO_ROUTES:
    'FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE_VOLVO_ROUTES',
  FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE_AUDI_ROUTES:
    'FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE_AUDI_ROUTES',
  FEATURE_SHOW_NEW_NAV_MENU: 'FEATURE_SHOW_NEW_NAV_MENU',
  FEATURE_FORCE_LEGAL_UPDATES_TO_SHOW: 'FEATURE_FORCE_LEGAL_UPDATES_TO_SHOW',
  FEATURE_AUDI_ADOBE_TRACKING_SCRIPT_IS_ENABLED: 'FEATURE_AUDI_ADOBE_TRACKING_SCRIPT_IS_ENABLED',
  FEATURE_AUDI_ADOBE_TRACKING_SCRIPT_IS_ENABLED_BRANDS: 'FEATURE_AUDI_ADOBE_TRACKING_SCRIPT_IS_ENABLED_BRANDS',
};
